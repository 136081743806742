import { BlogArticle, OfferUtils } from '@stars-ecom/shared-organisms-ui'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import set from 'lodash/set'
import React from 'react'
import { Helmet } from 'react-helmet'

import { lengthLimiter, siteFormatter } from '../utils/metaTitleFormatter'
import { simplifyGQLResult } from '../utils/strapi'

const BlogArticlePage = (props) => {
  const { data, pageContext, location } = props
  const blogArticle = simplifyGQLResult(get(data, 'strapi.blogArticle'))
  if (blogArticle?.media?.mime === 'video/mp4') {
    set(blogArticle?.media, `customFields.sourceOrigin`, blogArticle?.media?.url)
    set(blogArticle?.media, `mimeType`, blogArticle?.media?.mime)
    set(blogArticle?.media, `image`, blogArticle?.poster?.image)
  }
  const products = OfferUtils.removeNoOfferProducts(get(data, 'vendure.products.items', []))
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'chakra-ui-light'
        }}>
        <meta charSet="utf-8" />
        <title>
          {lengthLimiter(blogArticle?.title)} - {siteFormatter(pageContext?.website?.title)}
        </title>
        <meta name="description" content={blogArticle?.hook} />
      </Helmet>
      <BlogArticle blogArticle={blogArticle} products={products} location={location} />
    </>
  )
}

export const query = graphql`
  query blogArticle($idBlogArticle: ID, $idProducts: [String!]) {
    strapi {
      blogArticle(id: $idBlogArticle) {
        data {
          id
          attributes {
            title
            subTitle
            hook
            content
            startDate
            media {
              data {
                attributes {
                  url
                  mime
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, width: 1000, height: 562)
                    }
                  }
                }
              }
            }
            poster {
              data {
                attributes {
                  url
                  mime
                  image {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, width: 1000, height: 562)
                    }
                  }
                }
              }
            }
            products {
              data {
                attributes {
                  idProduct
                }
              }
            }
            blog_taxonomy {
              data {
                attributes {
                  title
                }
              }
            }
          }
        }
      }
    }
    vendure {
      products(options: { filter: { id: { in: $idProducts } } }) {
        items {
          id
          slug
          name
          featuredAsset {
            id
            source
            mimeType
            customFields {
              sourceOrigin
            }
            image {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, width: 215, height: 172)
              }
            }
          }
          variants {
            id
            customFields {
              offers
              externalCode
              renewPrice
              productType
            }
          }
          customFields {
            urlKey
            argContext
          }
        }
      }
    }
  }
`

BlogArticlePage.pageType = 'blog'

export default BlogArticlePage
